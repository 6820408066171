import React, {useEffect, useState} from 'react'
import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextareaAutosize,
    TextField,
    Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {useNavigate} from 'react-router-dom'
import SubmitButton from '../../../components/mui/button/SubmitButton'
import {sendErrorMessage, sendSuccessMessage} from '../../../actions'
import {fetchFormCategories, submitFormHead} from '../../../actions/forms/formActions'
import makeRandString from '../../../utils/RandString'
import {FORM_BUILDER_HEAD_MODE, FormCreatorHeadValidator} from './formBuilderData'
import {EMAIL} from '../../hr/employeeHelper'

const useStyles = makeStyles(theme => ({
    formHeaderTitle: {
        '& input': {
            fontSize: '2rem'
        }
    },
    formHeaderDescription: {},
    formControl: {
        paddingTop: theme.spacing(1),
        marginBottom: '2rem'
    },
    customTextArea: {
        padding: '6px 0 7px',
        color: '#263238',
        fontSize: '1rem',
        boxSizing: 'border-box',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.1876em',
        letterSpacing: '0.00938em',
        outline: 'none',
        border: 'none',
        borderBottom: '#949494 1px solid',
        '&:hover': {
            borderBottom: '#263238 2px solid'
        },
        '&:focus': {
            borderBottom: `${theme.palette.primary.main} 2px solid`
        },
        '&:disabled': {
            borderBottom: '#949494 1px dotted',
            color: 'rgba(0, 0, 0, 0.38)',
            backgroundColor: 'transparent'
        }
    },
    customTextLabel: {
        fontSize: '0.8rem'
    },
    emailHint: {
        marginLeft: '0!important'
    }
}))

const schema = yup.object().shape(FormCreatorHeadValidator)
function FormBuilderFormHead({formId, setFormId, mode, formToEdit = {}}) {
    const navigate = useNavigate()
    const classes = useStyles()
    const dispatch = useDispatch()
    const allCategories = useSelector(state => state?.forms.allCategories)

    const [formCategories, setFormCategories] = useState([])
    const [selectedCat, setSelectedCat] = useState('NULL')
    const [selectedFormCat, setSelectedFormCat] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isCatLoaded, setIsCatLoaded] = useState(false)

    const [formNameText, setFormNameText] = useState(
        [FORM_BUILDER_HEAD_MODE.EDIT, FORM_BUILDER_HEAD_MODE.CLONE].includes(mode) ? formToEdit?.name || '' : '技术咨询'
    )
    const [formSlugText, setFormSlugText] = useState(
        mode === FORM_BUILDER_HEAD_MODE.NEW ? makeRandString(10) : formToEdit?.slug
    )
    const [isGenSlug, setIsGenSlug] = useState(mode === FORM_BUILDER_HEAD_MODE.NEW)
    const [formAlertEmail, setFormAlertEmail] = useState(
        [FORM_BUILDER_HEAD_MODE.EDIT, FORM_BUILDER_HEAD_MODE.CLONE].includes(mode)
            ? formToEdit?.alertEmail?.replace(/,/g, '\n') || ''
            : ''
    )
    const currentTab = useSelector(state => state?.forms.currentFormTab)
    const loadData = () => {
        ;(async () => {
            setIsCatLoaded(await fetchFormCategories()(dispatch))
        })()
    }
    useEffect(loadData, [])
    useEffect(() => {
        if (currentTab === 0) {
            setSelectedFormCat('Dynamic Form')
        } else {
            setSelectedFormCat('Web Form')
        }
        // currentTab === 0 ? setSelectedFormCat('Dynamic Form') : setSelectedFormCat('Web Form')
    }, [currentTab])
    useEffect(() => {
        if (allCategories && isCatLoaded) {
            setFormCategories(allCategories)
            if ([FORM_BUILDER_HEAD_MODE.EDIT, FORM_BUILDER_HEAD_MODE.CLONE].includes(mode) && formToEdit.category)
                setSelectedCat(formToEdit.category.id)
            setIsLoaded(true)
        }
    }, [isCatLoaded, allCategories, mode, formToEdit.category])

    const {register, handleSubmit, errors, setError} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })

    const formHeadSubmit = formData => {
        setIsSaving(true)

        // Tack on extra details required for submission
        const data =
            mode === FORM_BUILDER_HEAD_MODE.EDIT
                ? {
                      ...formData,
                      id: formToEdit.id,
                      formCategory: selectedFormCat
                  }
                : formData
        if (selectedCat !== 'NULL') {
            data.categoryId = selectedCat
        }
        data.formCategory = selectedFormCat
        // test alert email
        let alertEmails = []
        let hasEmailErr = false
        if (formAlertEmail.trim().length > 0) {
            alertEmails = formAlertEmail.trim().split('\n')
            alertEmails.forEach(email => {
                // test if email matches regex
                if (!email.match(new RegExp(EMAIL))) {
                    hasEmailErr = true
                    setError('alertEmail', {type: 'manual', message: `"${email}" is not a valid email`})
                }
            })
        }

        if (hasEmailErr) {
            setIsSaving(false)
            return
        }
        data.alertEmail = alertEmails.join(',')

        submitFormHead(
            data,
            mode === FORM_BUILDER_HEAD_MODE.EDIT,
            id => onSuccess(id),
            err => onError(err)
        )(dispatch)
    }

    const onSuccess = id => {
        sendSuccessMessage(dispatch, 'Form Submitted')
        setFormId(id)
        if (mode === FORM_BUILDER_HEAD_MODE.EDIT) {
            setTimeout(() => {
                setIsSaving(false)
                navigate('/app/forms')
            }, 1000)
        }
        if (currentTab === 1) {
            setTimeout(() => {
                setIsSaving(false)
                navigate('/app/forms')
            }, 1000)
        }
    }

    const onError = errMsg => {
        sendErrorMessage(dispatch, errMsg)
        setIsSaving(false)
    }

    const renderForm = () => (
        <form onSubmit={handleSubmit(formHeadSubmit)}>
            <Grid container spacing={1} direction="row">
                <Grid item xs={12}>
                    <FormControl fullWidth className={classes.formControl}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Form Name"
                            required
                            className={classes.formHeaderTitle}
                            disabled={formId !== -1}
                            name="formName"
                            inputRef={register}
                            error={Boolean(errors.formName?.message)}
                            helperText={errors.formName?.message}
                            value={formNameText}
                            onChange={e => {
                                setFormNameText(e.currentTarget.value)
                                if (isGenSlug) {
                                    setFormSlugText(e.currentTarget.value.toLowerCase().replace(/ /g, '-'))
                                }
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth className={classes.formControl}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Form Description"
                            required
                            defaultValue={
                                [FORM_BUILDER_HEAD_MODE.EDIT, FORM_BUILDER_HEAD_MODE.CLONE].includes(mode)
                                    ? formToEdit?.description || ''
                                    : '您的咨询我们会认真聆听，24h给出回答'
                            }
                            className={classes.formHeaderDescription}
                            disabled={formId !== -1}
                            name="formDescription"
                            inputRef={register}
                            error={Boolean(errors.formDescription?.message)}
                            helperText={errors.formDescription?.message}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth className={classes.formControl}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Form Slug"
                            required
                            className={classes.formHeaderDescription}
                            disabled={formId !== -1}
                            name="formSlug"
                            inputRef={register}
                            error={Boolean(errors.formSlug?.message)}
                            helperText={errors.formSlug?.message}
                            value={formSlugText}
                            onChange={e => {
                                setIsGenSlug(false)
                                setFormSlugText(e.currentTarget.value)
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth className={classes.formControl}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Form Complete Title"
                            required
                            defaultValue={
                                [FORM_BUILDER_HEAD_MODE.EDIT, FORM_BUILDER_HEAD_MODE.CLONE].includes(mode)
                                    ? formToEdit?.formCompleteTitle || ''
                                    : 'Thank you for your interest'
                            }
                            className={classes.formHeaderDescription}
                            disabled={formId !== -1}
                            name="formCompleteTitle"
                            inputRef={register}
                            error={Boolean(errors.formCompleteTitle?.message)}
                            helperText={errors.formCompleteTitle?.message}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth className={classes.formControl}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Form Complete Message"
                            required
                            defaultValue={
                                [FORM_BUILDER_HEAD_MODE.EDIT, FORM_BUILDER_HEAD_MODE.CLONE].includes(mode)
                                    ? formToEdit?.formCompleteDescription || ''
                                    : 'Your form has been submitted, please do not submit multiple times:)\\nShould there be any other concerns, please email us at: info@hibitbyte.io'
                            }
                            className={classes.formHeaderDescription}
                            disabled={formId !== -1}
                            name="formCompleteDescription"
                            inputRef={register}
                            error={Boolean(errors.formCompleteDescription?.message)}
                            helperText={errors.formCompleteDescription?.message}
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth className={classes.formControl}>
                        <TextField
                            fullWidth
                            size="small"
                            label="URL Message"
                            required
                            defaultValue={
                                [FORM_BUILDER_HEAD_MODE.EDIT, FORM_BUILDER_HEAD_MODE.CLONE].includes(mode)
                                    ? formToEdit?.formCompleteRedirectNotice || ''
                                    : 'Click to return to our Official Website'
                            }
                            className={classes.formHeaderDescription}
                            disabled={formId !== -1}
                            name="formCompleteRedirectNotice"
                            inputRef={register}
                            error={Boolean(errors.formCompleteRedirectNotice?.message)}
                            helperText={errors.formCompleteRedirectNotice?.message}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth className={classes.formControl}>
                        <TextField
                            fullWidth
                            size="small"
                            label="URL"
                            required
                            defaultValue={
                                [FORM_BUILDER_HEAD_MODE.EDIT, FORM_BUILDER_HEAD_MODE.CLONE].includes(mode)
                                    ? formToEdit?.formCompleteRedirectLink || ''
                                    : 'https://hibitbyte.io'
                            }
                            className={classes.formHeaderDescription}
                            disabled={formId !== -1}
                            name="formCompleteRedirectLink"
                            inputRef={register}
                            error={Boolean(errors.formCompleteRedirectLink?.message)}
                            helperText={errors.formCompleteRedirectLink?.message}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                    <FormControl fullWidth className={classes.formControl} disabled={formId !== -1}>
                        <FormLabel className={classes.customTextLabel}>Alert Email</FormLabel>
                        <TextareaAutosize
                            minRows={1}
                            name="alertEmail"
                            disabled={formId !== -1}
                            className={classes.customTextArea}
                            placeholder="List of emails to send here..."
                            value={formAlertEmail}
                            onChange={e => setFormAlertEmail(e.target.value)}
                        />
                        <FormHelperText className={classes.emailHint} error={Boolean(errors.alertEmail?.message)}>
                            {errors.alertEmail?.message
                                ? errors.alertEmail?.message
                                : 'Enter list of emails to send alert to. One per line, leave blank to disable alerts'}
                        </FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel>Category</InputLabel>
                        <Select
                            size="small"
                            disabled={formId !== -1}
                            value={selectedCat}
                            onChange={e => {
                                setSelectedCat(e.target.value)
                            }}
                        >
                            <MenuItem value="NULL">None</MenuItem>
                            {formCategories.map(c => (
                                <MenuItem key={c.id} value={c.id}>
                                    {c.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* <Grid item xs={12} md={12}> */}
                {/*    <FormControl fullWidth className={classes.formControl}> */}
                {/*        <InputLabel>Form Category</InputLabel> */}
                {/*        <Select */}
                {/*            size='small' */}
                {/*            disabled={formId !== -1} */}
                {/*            value={selectedFormCat} */}
                {/*            onChange={(e) => { */}
                {/*                setSelectedFormCat(e.target.value) */}
                {/*            }} */}
                {/*        > */}
                {/*            <MenuItem value='Dynamic Form'>Dynamic Form</MenuItem> */}
                {/*            <MenuItem value='Web Form'>Web Form</MenuItem> */}

                {/*        </Select> */}
                {/*    </FormControl> */}
                {/* </Grid> */}

                <Grid item xs={10} />
                <Grid item xs={2}>
                    {formId === -1 ? (
                        <SubmitButton
                            isSaving={isSaving}
                            text="Submit"
                            isSavingText="Submitting"
                            fullWidth
                            variant="contained"
                            color="primary"
                        />
                    ) : (
                        <Button disabled fullWidth variant="contained" color="primary">
                            Submitted
                        </Button>
                    )}
                </Grid>
            </Grid>
        </form>
    )

    return <div> {isLoaded ? renderForm() : <Typography variant="h2">Loading</Typography>} </div>
}

export default FormBuilderFormHead
