// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backgroundCtnr {
  display: flex;
  height: max-content;
}
.backgroundCtnr .backgroundImg {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  background: center no-repeat;
  filter: blur(10px);
  z-index: -1;
  transform: scale(2);
}
.backgroundCtnr .backgroundVideo {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  left: 0;
  transform: scale(1.3);
  overflow: hidden;
  filter: blur(3px);
}
.backgroundCtnr .backgroundBlack {
  position: relative;
  height: max-content;
  width: 100%;
  left: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/views/auth/Background.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,OAAA;EACA,QAAA;EACA,4BAAA;EACA,kBAAA;EACA,WAAA;EACA,mBAAA;AAEJ;AAAE;EACE,eAAA;EACA,MAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,qBAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AAEE;EACE,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,OAAA;EACA,QAAA;AAAJ","sourcesContent":[".backgroundCtnr {\n  display: flex;\n  height: max-content;\n  .backgroundImg{\n    position: absolute;\n    width: 100%;\n    height: 100vh;\n    left: 0;\n    right: 0;\n    background: center no-repeat;\n    filter: blur(10px);\n    z-index: -1;\n    transform: scale(2.0);\n  }\n  .backgroundVideo{\n    position: fixed;\n    top: 0;\n    width: 100%;\n    z-index: -1;\n    height: 100%;\n    left: 0;\n    transform: scale(1.3);\n    overflow: hidden;\n    filter: blur(3px);\n\n  }\n\n  .backgroundBlack {\n    position: relative;\n    height: max-content;\n    width: 100%;\n    left: 0;\n    right: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
