// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nprogress .spinner {
    top: 65px;
    right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/public/LoadingProgress.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,WAAW;AACf","sourcesContent":["#nprogress .spinner {\n    top: 65px;\n    right: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
